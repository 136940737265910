.title_mobile {
    display: none;
}

@media screen and (max-width: 600px) {
    .title_desktop {
        display: none;
    }

    .title_mobile {
        display: block;
    }

    .submitButton {
        align-self: flex-end;
    }
}
