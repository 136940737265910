.title {
  font-family: 'Inter', serif;
  color: var(--text-primary);
  font-weight: 700;
  font-size: 2.7rem;
  margin-bottom: 0.4rem;
}

.inputHelperTextContainer {
  bottom: 1rem !important;
}

.text {
  font-size: 1.4rem;
  line-height: 2rem;
  color: var(--text-primary);
}

.marginBottom {
  margin-bottom: 3rem;
}

.marginTop {
  margin-top: 3.2rem;
}

.marginBoth {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.error {
  color: red;
  font-size: 10px;
  line-height: 15px;
  margin-top: 8px;
}

.link {
  cursor: pointer;
  font-weight: 500;
}

.link_blue {
  color: var(--primary-color);
}

.link_grey {
  color: var(--text-secondary);
}

.link:hover {
  opacity: 0.8;
}

.subtitle {
  color: #1E1E1E;
  font-size: 1.4rem;
  line-height: 2rem;
}

.centerLink {
  width: 100%;
  text-align: center;
  font-size: 1.4rem;
  line-height: 2rem;
}

.primaryCaption {
  color: var(--primary-color);
}

.secondaryCaption {
  color: var(--secondary-color);
}

.button {
  width: 100%;
}

@media screen and (max-width: 600px) {
  .loginForm {
    display: grid;
    grid-template-rows: repeat(4, min-content);
  }
}
