.errorMessage {
  margin-top: 1rem;
}

.resend {
  font-size: 1.4rem;
  line-height: 2rem;
  color: var(--text-secondary);
  display: flex;
  gap: 8px;
}

.login_margin {
  margin: 12px 0 24px;
}

.signup_margin {
  margin: 12px 0 24px;
}

.timer {
  margin-left: 10px;
}

.disabled {
  color: rgba(30, 30, 30, 0.38);
  cursor: default;
}

.disabled:hover {
  opacity: 1;
  cursor: default;
}

.loginCodeInputWrapper {
  margin-top: 3.2rem;
}

.signUpCodeInputWrapper {
  margin-top: 1.6rem;
}

.codeInputContainer {
  display: flex !important;
  justify-content: space-between;
}

@media screen and (max-width: 600px) {
  .resend {
    margin: 12px 0 24px;
  }

  .loginCodeInputWrapper {
    margin-top: 0;
  }

  .codeInputContainer {
    display: flex !important;
    justify-content: flex-start;
    gap: 16px;
  }
}

@media screen and (max-width: 374px) {
  .codeInputContainer {
    gap: 12px;
  }
}

@media screen and (max-width: 340px) {
  .codeInputContainer {
    gap: 8px;
  }
}