.loginContainer {
    min-height: 312px;
    grid-template-columns: minmax(250px, 340px);
}

@media only screen and (max-width: 600px) {
    .loginContainer {
        min-height: calc(100vh - var(--header-height));
        grid-template-columns: minmax(250px, 600px);
        grid-template-rows: repeat(3, min-content);
    }
}

@media only screen and (max-height: 655px) and (min-width: 600px) {
    .loginContainer {
        margin: var(--header-height) 0 80px 0;
        align-items: flex-start;
    }
}
