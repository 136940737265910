.loader {
  pointer-events: none;
  width: 4.5rem;
  height: 4.5rem;
  border: 0.6rem solid transparent;
  border-color: #eee;
  border-top-color: var(--secondary-color);
  border-radius: 50%;
  animation: loader 1s linear infinite;
}

@keyframes loader {
  100% {
    transform: rotate(360deg);
  }
}
