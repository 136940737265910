.loginLink {
  margin-top: 3.2rem;
}

.signUpContainer, .loginSignUpContainer {
  grid-template-columns: minmax(250px, 327px);
}

@media only screen and (max-width: 600px) {
  .loginLink {
    margin-top: 2.4rem;
  }

  .signUpHeight, .userInfoHeight {
    min-height: calc(100vh - var(--header-height));
  }

  .signUpContainer, .loginSignUpContainer {
    min-height: calc(100vh - var(--header-height));
    grid-template-columns: minmax(250px, 600px);
  }

  .signUpContainer {
    grid-template-rows: repeat(6, min-content);
  }
}

@media only screen and (max-height: 868px) and (min-width: 600px) {
  .signUpContainer, .loginSignUpContainer {
    margin: var(--header-height) 0 80px 0;
  }

  .signUpContainer {
    align-items: flex-start;
  }
}
