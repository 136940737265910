.button {
  outline: none;
  border: none;
  cursor: pointer;
  background: var(--primary-color);
  color: white;
  border-radius: 8px;
  font-weight: 400;
  font-size: 1.6rem;
  text-align: center;
  padding: 12px 0;
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
  height: 56px;
}

.button:hover {
  background: #000093;
}

.button.border {
  border: 1px solid black;
  background: white;
  color: black;
}

.button.border:hover {
  background: #e6e8ee;
}

.button.disabled {
  color: #9AA0A7;
  background: #E4E5E7;
  cursor: default;
}

.loader {
  border-width: 0.4rem;
  border-bottom-style: solid;
  border-color: #d9dce8;
  border-top-color: #fff;
  width: 2.6rem;
  height: 2.6rem;
  margin: 0 auto;
}
