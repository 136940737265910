.headContainer {
  margin-bottom: 4rem;
}

.head {
  color: var(--text-primary);
  font-size: 2.4rem;
  font-weight: bold;
  margin: 0 0 8px 0;
  line-height: 3.2rem;
}

.description {
  font-size: 1.4rem;
  color: #1E1E1E;
  margin: 0;
  line-height: 2rem;
}
