.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--background);
  position: relative;
}

.routesContainer {
  display: grid;
  justify-content: space-around;
  background: #ffffff;
  border-radius: 16px;
  position: relative;
  z-index: 1;
  padding: 40px;
}

.loaderContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .container {
    align-items: flex-start;
  }

  .routesContainer {
    min-width: 100%;
    padding: 36px 24px;
    box-sizing: border-box;
    top: var(--header-height);
    border-radius: 0;
  }
}
