@import url(https://use.typekit.net/row5qom.css);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap);
.App_container__1MQN3 {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--background);
  position: relative;
}

.App_routesContainer__3aqsh {
  display: grid;
  justify-content: space-around;
  background: #ffffff;
  border-radius: 16px;
  position: relative;
  z-index: 1;
  padding: 40px;
}

.App_loaderContainer__cyIqk {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .App_container__1MQN3 {
    align-items: flex-start;
  }

  .App_routesContainer__3aqsh {
    min-width: 100%;
    padding: 36px 24px;
    box-sizing: border-box;
    top: var(--header-height);
    border-radius: 0;
  }
}

.Loader_loader__1j2Bs {
  pointer-events: none;
  width: 4.5rem;
  height: 4.5rem;
  border: 0.6rem solid transparent;
  border-color: #eee;
  border-top-color: var(--secondary-color);
  border-radius: 50%;
  -webkit-animation: Loader_loader__1j2Bs 1s linear infinite;
          animation: Loader_loader__1j2Bs 1s linear infinite;
}

@-webkit-keyframes Loader_loader__1j2Bs {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes Loader_loader__1j2Bs {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.common_title__25aiY {
  font-family: 'Inter', serif;
  color: var(--text-primary);
  font-weight: 700;
  font-size: 2.7rem;
  margin-bottom: 0.4rem;
}

.common_inputHelperTextContainer__OsEIb {
  bottom: 1rem !important;
}

.common_text__3bqvq {
  font-size: 1.4rem;
  line-height: 2rem;
  color: var(--text-primary);
}

.common_marginBottom__2jTZ1 {
  margin-bottom: 3rem;
}

.common_marginTop__2Rlhn {
  margin-top: 3.2rem;
}

.common_marginBoth__sWKMP {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.common_error__ruI-C {
  color: red;
  font-size: 10px;
  line-height: 15px;
  margin-top: 8px;
}

.common_link__2r9LY {
  cursor: pointer;
  font-weight: 500;
}

.common_link_blue__3p-78 {
  color: var(--primary-color);
}

.common_link_grey__2apRK {
  color: var(--text-secondary);
}

.common_link__2r9LY:hover {
  opacity: 0.8;
}

.common_subtitle__3ukck {
  color: #1E1E1E;
  font-size: 1.4rem;
  line-height: 2rem;
}

.common_centerLink__2Xzh3 {
  width: 100%;
  text-align: center;
  font-size: 1.4rem;
  line-height: 2rem;
}

.common_primaryCaption__1vFn4 {
  color: var(--primary-color);
}

.common_secondaryCaption__11Q4W {
  color: var(--secondary-color);
}

.common_button__MObgi {
  width: 100%;
}

@media screen and (max-width: 600px) {
  .common_loginForm__11LrE {
    display: grid;
    grid-template-rows: repeat(4, -webkit-min-content);
    grid-template-rows: repeat(4, min-content);
  }
}

.ContactUs_highMargin__3BTzj {
  margin-top: 8rem;
}

.ContactUs_smallMargin__38qBq {
  margin-top: 1.6rem;
}

.ContactUs_tinyMargin__2FWkh {
  margin-top: 0.8rem;
}

@media only screen and (max-width: 600px) {
  .ContactUs_smallMargin__38qBq {
    margin-top: 2.4rem;
  }
}

.Button_button__3QF_k {
  outline: none;
  border: none;
  cursor: pointer;
  background: var(--primary-color);
  color: white;
  border-radius: 8px;
  font-weight: 400;
  font-size: 1.6rem;
  text-align: center;
  padding: 12px 0;
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
  height: 56px;
}

.Button_button__3QF_k:hover {
  background: #000093;
}

.Button_button__3QF_k.Button_border__3pizj {
  border: 1px solid black;
  background: white;
  color: black;
}

.Button_button__3QF_k.Button_border__3pizj:hover {
  background: #e6e8ee;
}

.Button_button__3QF_k.Button_disabled__2c3jr {
  color: #9AA0A7;
  background: #E4E5E7;
  cursor: default;
}

.Button_loader__sd-zg {
  border-width: 0.4rem;
  border-bottom-style: solid;
  border-color: #d9dce8;
  border-top-color: #fff;
  width: 2.6rem;
  height: 2.6rem;
  margin: 0 auto;
}

.FirstTimeAuth_subtitle__3wv9N {
  color: #5f6573;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 500;
}

.FirstTimeAuth_formWrapper__fHoZS {
  margin-top: 5.2rem;
}

.FirstTimeAuth_trailingIcon__TlAak {
  cursor: pointer;
  outline: none;
}

.FirstTimeAuth_button__24TCu {
  margin-top: 0.8rem;
}

@media only screen and (max-width: 600px) {
  .FirstTimeAuth_button__24TCu {
    align-self: flex-end;
  }
}

.MobileCodeCommon_errorMessage__3KvF8 {
  margin-top: 1rem;
}

.MobileCodeCommon_resend__35FAS {
  font-size: 1.4rem;
  line-height: 2rem;
  color: var(--text-secondary);
  display: flex;
  grid-gap: 8px;
  gap: 8px;
}

.MobileCodeCommon_login_margin__34Z7j {
  margin: 12px 0 24px;
}

.MobileCodeCommon_signup_margin__1y4dd {
  margin: 12px 0 24px;
}

.MobileCodeCommon_timer__2r1OF {
  margin-left: 10px;
}

.MobileCodeCommon_disabled__7TOnh {
  color: rgba(30, 30, 30, 0.38);
  cursor: default;
}

.MobileCodeCommon_disabled__7TOnh:hover {
  opacity: 1;
  cursor: default;
}

.MobileCodeCommon_loginCodeInputWrapper__8sSti {
  margin-top: 3.2rem;
}

.MobileCodeCommon_signUpCodeInputWrapper__3iIE7 {
  margin-top: 1.6rem;
}

.MobileCodeCommon_codeInputContainer__3OviJ {
  display: flex !important;
  justify-content: space-between;
}

@media screen and (max-width: 600px) {
  .MobileCodeCommon_resend__35FAS {
    margin: 12px 0 24px;
  }

  .MobileCodeCommon_loginCodeInputWrapper__8sSti {
    margin-top: 0;
  }

  .MobileCodeCommon_codeInputContainer__3OviJ {
    display: flex !important;
    justify-content: flex-start;
    grid-gap: 16px;
    gap: 16px;
  }
}

@media screen and (max-width: 374px) {
  .MobileCodeCommon_codeInputContainer__3OviJ {
    grid-gap: 12px;
    gap: 12px;
  }
}

@media screen and (max-width: 340px) {
  .MobileCodeCommon_codeInputContainer__3OviJ {
    grid-gap: 8px;
    gap: 8px;
  }
}
.Verification_title_mobile__xDHuw {
    display: none;
}

@media screen and (max-width: 600px) {
    .Verification_title_desktop__275hk {
        display: none;
    }

    .Verification_title_mobile__xDHuw {
        display: block;
    }

    .Verification_submitButton__wJSHP {
        align-self: flex-end;
    }
}

.Login_loginContainer__2z02F {
    min-height: 312px;
    grid-template-columns: minmax(250px, 340px);
}

@media only screen and (max-width: 600px) {
    .Login_loginContainer__2z02F {
        min-height: calc(100vh - var(--header-height));
        grid-template-columns: minmax(250px, 600px);
        grid-template-rows: repeat(3, -webkit-min-content);
        grid-template-rows: repeat(3, min-content);
    }
}

@media only screen and (max-height: 655px) and (min-width: 600px) {
    .Login_loginContainer__2z02F {
        margin: var(--header-height) 0 80px 0;
        align-items: flex-start;
    }
}

.HeadLegend_headContainer__1fH8b {
  margin-bottom: 4rem;
}

.HeadLegend_head__2ZmzR {
  color: var(--text-primary);
  font-size: 2.4rem;
  font-weight: bold;
  margin: 0 0 8px 0;
  line-height: 3.2rem;
}

.HeadLegend_description__VIWw8 {
  font-size: 1.4rem;
  color: #1E1E1E;
  margin: 0;
  line-height: 2rem;
}

.VerificationCode_subtitle__181VI {
    color: var(--text-primary);
    font-size: 1.4rem;
    line-height: 2rem;
}

.VerificationCode_subtitle_margin__3Jeuh {
    margin-bottom: 1.6rem;
}

.VerificationCode_text_margin__5A139 {
    margin-bottom: 1.6rem;
}

@media screen and (max-width: 600px) {
    .VerificationCode_form__1NY9J {
        display: grid;
        grid-template-rows: auto auto auto 1fr;
    }

    .VerificationCode_submit__10js2 {
        align-self: flex-end;
    }
}

.SignUp_loginLink__3WDFd {
  margin-top: 3.2rem;
}

.SignUp_signUpContainer__1GIWW, .SignUp_loginSignUpContainer__1nsLa {
  grid-template-columns: minmax(250px, 327px);
}

@media only screen and (max-width: 600px) {
  .SignUp_loginLink__3WDFd {
    margin-top: 2.4rem;
  }

  .SignUp_signUpHeight__1YsdP, .SignUp_userInfoHeight__1nFGR {
    min-height: calc(100vh - var(--header-height));
  }

  .SignUp_signUpContainer__1GIWW, .SignUp_loginSignUpContainer__1nsLa {
    min-height: calc(100vh - var(--header-height));
    grid-template-columns: minmax(250px, 600px);
  }

  .SignUp_signUpContainer__1GIWW {
    grid-template-rows: repeat(6, -webkit-min-content);
    grid-template-rows: repeat(6, min-content);
  }
}

@media only screen and (max-height: 868px) and (min-width: 600px) {
  .SignUp_signUpContainer__1GIWW, .SignUp_loginSignUpContainer__1nsLa {
    margin: var(--header-height) 0 80px 0;
  }

  .SignUp_signUpContainer__1GIWW {
    align-items: flex-start;
  }
}

.Header_container__1VC87 {
  position: absolute;
  top: 0;
  width: 100%;
  height: var(--header-height);
  background: var(--background);
  display: flex;
  align-items: center;
  padding: 24px 24px 24px 16px;
  box-sizing: border-box;
  justify-content: space-between;
}

.Header_container_white__3RfQP {
  background: white;
}

.Header_logoContainer__gaEZM {
  display: flex;
}

.Header_logo__3_SJs {
  width: 95px;
}

.Header_linkLogo__1ORDN {
  width: 20px;
}

.Header_nav__3yRlw {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
}

.Header_mobileNav__2OYCO {
  display: flex;
  flex-direction: column;
  padding: var(--header-height) 24px 24px;
  grid-gap: 8px;
  gap: 8px;
}

.Header_navItem__38LDp {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: 0.15px;
  color: var(--text-primary);
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  padding: 12px;
  border-radius: 8px;
  align-items: center;
}

.Header_navItem__38LDp:hover {
  background-color: rgba(0, 0, 197, 0.04);
}

.Header_burger__1X2vN {
  display: none;
}

.Header_dropdownMenu__3CZzG {
  display: none;
}

@media only screen and (max-width: 600px) {
  .Header_nav__3yRlw {
    display: none;
  }

  .Header_navItem__38LDp {
    padding: 8px;
  }

  .Header_navItem__38LDp:hover {
    background-color: transparent;
  }

  .Header_burger__1X2vN {
    z-index: 1301;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    cursor: pointer;
    border-radius: 8px;
    border: none;
    outline: none;
    background: none;
  }

  .Header_burger_white__2ev2N {
    background-color: white;
  }

  .Header_burger_grey__2SnuU {
    background-color: var(--background);
  }
  
  .Header_dropdownMenu__3CZzG {
    display: block;
  }
}

html {
  font-size: 10px;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif, -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(130, 135, 176, 0.8);
  color: var(--text-secondary);
  font-size: 1.6rem;
  font-weight: 400;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input:focus-visible {
  outline-color: #0000C5;
  outline-color: var(--primary-color);
}

:root {
  --primary-color: #0000C5;
  --secondary-color: #f15727;
  --primary-dark-color: #041553;
  --mdc-theme-primary: #173293;
  --background: #F5F8FD;
  --text-primary: rgba(30, 30, 30, 0.87);
  --text-secondary: rgba(130, 135, 176, 0.8);
  --header-height: 76px;
}

