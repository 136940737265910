.container {
  position: absolute;
  top: 0;
  width: 100%;
  height: var(--header-height);
  background: var(--background);
  display: flex;
  align-items: center;
  padding: 24px 24px 24px 16px;
  box-sizing: border-box;
  justify-content: space-between;
}

.container_white {
  background: white;
}

.logoContainer {
  display: flex;
}

.logo {
  width: 95px;
}

.linkLogo {
  width: 20px;
}

.nav {
  display: flex;
  gap: 8px;
}

.mobileNav {
  display: flex;
  flex-direction: column;
  padding: var(--header-height) 24px 24px;
  gap: 8px;
}

.navItem {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: 0.15px;
  color: var(--text-primary);
  display: flex;
  gap: 8px;
  padding: 12px;
  border-radius: 8px;
  align-items: center;
}

.navItem:hover {
  background-color: rgba(0, 0, 197, 0.04);
}

.burger {
  display: none;
}

.dropdownMenu {
  display: none;
}

@media only screen and (max-width: 600px) {
  .nav {
    display: none;
  }

  .navItem {
    padding: 8px;
  }

  .navItem:hover {
    background-color: transparent;
  }

  .burger {
    z-index: 1301;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    cursor: pointer;
    border-radius: 8px;
    border: none;
    outline: none;
    background: none;
  }

  .burger_white {
    background-color: white;
  }

  .burger_grey {
    background-color: var(--background);
  }
  
  .dropdownMenu {
    display: block;
  }
}
