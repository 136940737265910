@import url('https://use.typekit.net/row5qom.css');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

html {
  font-size: 10px;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif, -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-secondary);
  font-size: 1.6rem;
  font-weight: 400;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input:focus-visible {
  outline-color: var(--primary-color);
}

:root {
  --primary-color: #0000C5;
  --secondary-color: #f15727;
  --primary-dark-color: #041553;
  --mdc-theme-primary: #173293;
  --background: #F5F8FD;
  --text-primary: rgba(30, 30, 30, 0.87);
  --text-secondary: rgba(130, 135, 176, 0.8);
  --header-height: 76px;
}
