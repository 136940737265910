.subtitle {
  color: #5f6573;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 500;
}

.formWrapper {
  margin-top: 5.2rem;
}

.trailingIcon {
  cursor: pointer;
  outline: none;
}

.button {
  margin-top: 0.8rem;
}

@media only screen and (max-width: 600px) {
  .button {
    align-self: flex-end;
  }
}
