.subtitle {
    color: var(--text-primary);
    font-size: 1.4rem;
    line-height: 2rem;
}

.subtitle_margin {
    margin-bottom: 1.6rem;
}

.text_margin {
    margin-bottom: 1.6rem;
}

@media screen and (max-width: 600px) {
    .form {
        display: grid;
        grid-template-rows: auto auto auto 1fr;
    }

    .submit {
        align-self: flex-end;
    }
}
